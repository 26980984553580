// solution based on:
// https://stackoverflow.com/questions/35138424/how-do-i-download-a-file-with-angular2
// https://github.com/eligrey/FileSaver.js/blob/master/src/FileSaver.js
import { MatSnackBar } from '@angular/material/snack-bar';
import { isArray } from 'lodash';
import { Moment } from 'moment';

import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';

import * as moment from 'moment';

import {
    NotificationOptions,
    NotificationSnackBarComponent,
    NotificationType
} from './notification-snackbar/notification-snackbar.component';
import { HttpErrorResponse, HttpParamsOptions } from '@angular/common/http';
import { AccountActorRole, ErrorMassages } from './shared.models';

// makes the file download
export function downloadFile(data: Blob, name = 'file'): void {
    if (!data) {
        return;
    }
    const file = new Blob([data], { type: data.type });
    const url = window.URL.createObjectURL(file);

    // create a link, click on it to download
    const a = document.createElement('a');
    a.download = name;
    a.rel = 'noopener';
    a.href = url;

    // make sure the URL is no longer available after 40s
    setTimeout(() => {
        URL.revokeObjectURL(a.href);
    }, 4e4); // 40s

    // "click" the node element
    setTimeout(() => {
        a.dispatchEvent(new MouseEvent('click'));
    }, 0);
}

// return an error depending on the type. If HttpError then message if from stark makes object stringify
export function getErrorMessage(error: any): string {
    const messages = [error?.error?.message, error?.message].filter(message => !!message);
    if (messages.length) {
        return messages.join();
    }
    return JSON.stringify(error);
}

export function qrCode2fa(secretKey: string): string {
    return `otpauth://totp/Solidus%20Labs?secret=${secretKey}`;
}

export function getAuthErrorMessage(errorResponse: HttpErrorResponse): string {
    if (errorResponse.status === 401) {
        if (errorResponse.error?.message === ErrorMassages.LOGIN_PASSWORD_EXPIRED) {
            return 'Password has expired.';
        } else {
            return 'User could not be authenticated.';
        }
    } else if (errorResponse.status === 400) {
        return 'User could not be authenticated.';
    } else if (errorResponse.status === 403) {
        return 'Unable to log in. Contact your administrator.';
    } else if (errorResponse.status === 429) {
        return errorResponse.error.message;
    } else if (errorResponse.status === 0) {
        return 'The system failed to login due to an unexpected error. Please try again later.';
    } else {
        return 'The system failed to login due to an unexpected error.';
    }
}

export function generateFullName(firstName: string, middleName: string, lastName: string, suffix: string): string {
    return `${firstName ? firstName + ' ' : ''}${middleName ? middleName + ' ' : ''}${lastName ? lastName + ' ' : ''}${
        suffix ? suffix : ''
    }`;
}

//open snackBar with config
export function openSnackbar(snackBar: MatSnackBar, message: string, type: NotificationType): void {
    snackBar.openFromComponent(NotificationSnackBarComponent, {
        ...NotificationOptions[type],
        data: message
    });
}

//open snackBar with duration
export function openSnackbarWithDuration(
    snackBar: MatSnackBar,
    message: string,
    type: NotificationType,
    duration?: number
): void {
    snackBar.openFromComponent(NotificationSnackBarComponent, {
        ...NotificationOptions[type],
        data: message,
        duration
    });
}

/*
    Is used to determine if provided argument is null or undefined.
    Example: isNullOrUndefined(value1)
    @param value - argument of any type
    @returns true or false
 */
export function isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
}

/*
    Is used to determine if some of provided arguments is null or undefined.
    Example: isAnyNullOrUndefined(value1, value2, value3)
    @param args - arguments of any type
    @returns true or false
 */
export function isAnyNullOrUndefined(...args): boolean {
    if (isNullOrUndefined(args)) {
        return true;
    }

    for (let i = 0; i < args.length; i++) {
        if (isNullOrUndefined(args[i])) {
            return true;
        }
    }

    return false;
}

// return N/A when value is null or undefined
export function defaultIfAbsent(value?: string, defaultValue = 'N/A'): string {
    return isNullOrUndefined(value) ? defaultValue : value;
}

/*
    Creates an object from the array indexed by the specified key.
    Example: const casesMap = associateBy<Case>('id', casesArray);
    @returns: {
        caseId1: Case1,
        caseId2: Case2
    }
 */
export function associateBy<T>(key: string, list: T[]): { [key: string]: T } {
    return list.reduce((map, obj) => {
        map[obj[key]] = obj;
        return map;
    }, {});
}

/*
    Creates a map from the array indexed by the specified key.
    Example: const casesMap = associateBy<Case>('id', casesArray);
    @returns: Map {
        caseId1: Case1,
        caseId2: Case2
    }
 */
export function mapAssociateBy<T>(key: string, list: T[]): Map<string, T> {
    return list.reduce((map, obj) => {
        map.set(obj[key], obj);
        return map;
    }, new Map());
}

// create fileName with fromDate and toDate
export function getDownloadFileNameWithTimestamp(
    name: string,
    fromDate: number,
    toDate?: number,
    format = 'csv'
): string {
    let fileName = `${name}-${moment.utc(fromDate).format('YYYY-MM-DD')}`;
    if (toDate) {
        fileName += `-${moment.utc(toDate).format('YYYY-MM-DD')}`;
    }
    return `${fileName}.${format}`;
}

// return timezone
export function getTimezone(): string {
    return new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2] ?? '';
}

export function humanConcatArray(items: string[], endConcat = '&', joinWith = ', '): string {
    if (!items) {
        return '';
    }

    if (items.length === 1) {
        return items[0];
    } else if (items.length === 2) {
        return `${items[0]} ${endConcat} ${items[1]}`;
    } else if (items.length > 2) {
        const firstElements = items.slice(0, items.length - 2);

        const lastElements = items.slice(items.length - 2, items.length);

        return [...firstElements, `${lastElements[0]} ${endConcat} ${lastElements[1]}`].join(joinWith);
    } else {
        return '';
    }
}

// Export array of rows to csv file
export function exportToCsv(filename: string, rows: any[][]): void {
    const blob = generateCSVBlob(filename, rows);

    downloadFile(blob, filename);
}

export function generateCSVBlob(filename: string, rows: any[][]): Blob {
    let csvFile = '';

    for (let i = 0; i < rows.length; i++) {
        csvFile += processCsvRow(rows[i]);
    }

    return new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
}

const processCsvRow = (row: any[]): string => {
    let rowValue = '';

    for (let i = 0; i < row.length; i++) {
        let innerValue = isNullOrUndefined(row[i]) ? '' : row[i].toString();

        if (row[i] instanceof Date) {
            innerValue = row[i].toLocaleString();
        }

        let result = innerValue.replace(/"/g, '""');

        if (result.search(/("|,|\n)/g) >= 0) {
            result = '"' + result + '"';
        }

        if (i > 0) {
            rowValue += ',';
        }

        rowValue += result;
    }

    return rowValue + '\n';
};

export function bufferDebounceTime<T>(time = 0): OperatorFunction<T, T[]> {
    return (source: Observable<T>) => {
        let bufferedValues: T[] = [];

        return source.pipe(
            tap(value => bufferedValues.push(value)),
            debounceTime(time),
            map(() => bufferedValues),
            tap(() => (bufferedValues = []))
        );
    };
}

export function minutesToSeconds(minutes: number): number {
    return minutes * 60;
}

export function secondsToMinutes(minutes: number): number {
    return minutes / 60;
}

export function truncateDecimalPlaces(number: number, decimalPlaces: number): number {
    if (!number) {
        return number;
    }
    return Math.trunc(number * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
}

export function getClientProfilesFromAARByRole(
    accountActorRoles: AccountActorRole[],
    role: AccountActorRole['role']
): string {
    const clientProfiles = accountActorRoles
        .filter(accountActorRole => accountActorRole.role === role)
        .map(accountActorRole => accountActorRole.clientProfile)
        .filter(clientProfile => !isNullOrUndefined(clientProfile));
    return clientProfiles.length ? [...new Set(clientProfiles)].join(', ') : '';
}

export function getClientProfilesFromAAR(accountActorRoles: AccountActorRole[]): string {
    const clientProfiles = accountActorRoles
        .map(accountActorRole => accountActorRole.clientProfile)
        .filter(clientProfile => !isNullOrUndefined(clientProfile));
    return clientProfiles.length ? [...new Set(clientProfiles)].join(', ') : '';
}

export function getReadableTimeFromSeconds(totalTimeInStatus: number): string {
    if (isNullOrUndefined(totalTimeInStatus)) {
        return 'N/A';
    }

    const minutesInDay = 1440;
    const minutesInHour = 60;

    const fromMoment = moment();
    const toMoment = fromMoment.clone().add(totalTimeInStatus, 'seconds');

    const diffInMinutes = toMoment.diff(fromMoment, 'minutes');

    const daysNum = diffInMinutes / minutesInDay;
    const days = Math.floor(daysNum);
    const hours = Math.floor((diffInMinutes % minutesInDay) / minutesInHour);

    if (days === 0 && hours === 0) {
        return 'Less than 1h';
    }

    return `${prepareTimeWithSuffix(days, 'd')} ${prepareTimeWithSuffix(hours, 'h')}`;
}

function prepareTimeWithSuffix(time: number, suffix: 'd' | 'h'): string {
    return time === 0 ? '' : time + suffix;
}

export function getDateFormat(date: Date | Moment | null): Date | null {
    if (isNullOrUndefined(date)) {
        return null;
    }

    if ('getTime' in (date as Date)) {
        return date as Date;
    } else if ('toDate' in (date as Moment)) {
        return (date as Moment).toDate();
    }

    return null;
}

export function camelCaseToWords(text: string): string {
    if (isNullOrUndefined(text) || text.length === 0) {
        return '';
    }
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
}

export function includesValue(firstValue: string, secondValue: string): boolean {
    return firstValue?.toLowerCase()?.includes(secondValue?.toLowerCase());
}

export function capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export type ScoreClass = 'low' | 'high' | 'medium';

export function getScoreClassByNumber(score: number, ucvFlag?: boolean): ScoreClass | null {
    const ScoreMaxRange = {
        LowMax: ucvFlag ? 30 : 49, // different gradation of low for UCV and other services
        MedMax: 70,
        HighMax: 100
    };

    if (score >= 0 && score <= ScoreMaxRange.LowMax) {
        return 'low';
    } else if (score <= ScoreMaxRange.MedMax) {
        return 'medium';
    } else if (score <= ScoreMaxRange.HighMax) {
        return 'high';
    }

    return null;
}

// using for set css class of review status in the clients table and ucv details
export function getReviewStatusClass(status: string): string {
    switch (status) {
        case 'SENT_FOR_REVIEW':
            return 'pending';
        case 'READY_FOR_CHECK':
            return 'running';
        case 'APPROVED':
            return 'completed';
        case 'REJECTED':
            return 'failed';
        default:
            return '';
    }
}

export const tzHttpParam: HttpParamsOptions = { fromObject: { tz: moment().utcOffset() / 60 } };

export function getTooltipForIdsContent(ids: string[], useHtml = false): string {
    if (!isArray(ids)) {
        return ids;
    }

    const maxCount = 12;
    const joinIds = (data: string[]) => data.join(`,${useHtml ? '<br>' : '\n'}`);
    const boldTag = () => (useHtml ? '<b>' : '');

    if (ids.length <= maxCount) {
        return joinIds(ids);
    }

    const visibleIds = joinIds(ids.slice(0, maxCount));
    const hiddenIdsCount = ids.length - maxCount;
    const additionalIds = `,${
        useHtml ? '<br>' : '\n'
    }... (+${hiddenIdsCount}) ${boldTag()}Click on the copy icon to see all.${boldTag()}`;

    return visibleIds + additionalIds;
}

export function getTooltipForIdsContentWithQuickActionsText(ids: string[], useHtml = false): string {
    const tooltip = getTooltipForIdsContent(ids, useHtml);
    const findText = 'Click on the copy icon to see all';

    if (tooltip.includes(findText)) {
        return tooltip.replace(
            'Click on the copy icon to see all',
            'Click on the copy icon to see all or click on the magnifying glass for Quick Search & Copy'
        );
    }

    return tooltip + '\nClick on the magnifying glass for Quick Search & Copy.';
}

export function numberWithCommas(value: number): string {
    if (isNullOrUndefined(value)) {
        return '';
    }

    const regex = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g; //NOSONAR

    return value.toString().replace(regex, ',');
}

export function formatNumberTwoDecimals(value: string | number): string {
    if (isNullOrUndefined(value)) {
        return '';
    }

    const inputString = value.toString();
    const [numericPart, currencyPart] = inputString.split(' ');
    const number = parseFloat(numericPart);

    if (isNaN(number)) {
        return inputString;
    }

    const formattedNum = formatToTwoDecimals(number);
    const numWithCommas = numberWithCommas(formattedNum);
    return currencyPart ? `${numWithCommas} ${currencyPart}` : numWithCommas;
}

export function formatToTwoDecimals(value: number): number {
    return value % 1 === 0 ? value : Math.floor(value * 100) / 100;
}

export const sortArrayOfObjects = <T, U extends string>(objects: T[], valueGetter: (obj: T) => U): T[] => {
    return objects.sort((a, b) => {
        const valueA = valueGetter(a).toString().toUpperCase();
        const valueB = valueGetter(b).toString().toUpperCase();

        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
    });
};
