// Make sure never to import shared.models, as it will generate a circular dependency.
import { CandlesSet } from './candles.class';
import { MSEventType } from './events.class';
import { MatSortable, Sort } from '@angular/material/sort';
import { IColumnFilters } from '../tables-with-filters/shared.models';
import { HttpErrorResponse, HttpParameterCodec } from '@angular/common/http';
import { IPeriod } from '../market-inspection-new/models/chart.models';
import { isNullOrUndefined } from './shared.functions';

export type Priority = 'HIGH' | 'MEDIUM' | 'LOW';
export type InvestorType = 'RETAIL' | 'INSTITUTIONAL' | 'UNKNOWN';
export const SSO_CLIENT_ID = 'SSO_CLIENT_ID';
export const SSO_TOKEN = 'SSO_TOKEN';
export const DASH_FORMAT = '\u2014';
export enum ErrorMassages {
    LOGIN_PASSWORD_EXPIRED = 'LOGIN_PASSWORD_EXPIRED',
    ACCOUNT_WITH_SAME_NAME_EXISTS = 'Account with same name already exists'
}

export interface TrdsComponent {
    readonly constructorName: string;
}

export interface ArrayPropsParser {
    name: string;
    parse: (field: string) => any;
}

export class User {
    name: string;
    id: number;
    email: string;
    isMaker?: boolean;
    isChecker?: boolean;

    static empty(): User {
        return {
            name: '',
            id: -1,
            email: ''
        };
    }
}

export type DetailedUser = User & {
    teamIds: number[];
    permissions: string[];
    status?: ActivationStatus;
    // ToDo: change to role id enum.
    roleIds?: number[];
    createdAt?: number;
    firstName?: string;
    lastName?: string;
    createdBy?: User;
    updatedAt?: number;
    updatedBy?: User;
    expireIn?: number;
    solidusClientId?: string;
};

export interface IDetailedUser {
    id: number;
    isApiUser?: boolean;
    isHmacUser?: boolean;
    firstName?: string;
    lastName?: string;
    email?: string;
    status?: ActivationStatus;
    permissions?: string[];
    teams?: { id: number; name: string }[];
    roles?: { id: number; name: string }[];
    createdAt?: number;
    createdBy?: User;
    updatedAt?: number;
    updatedBy?: User;
    secretKey?: string;
}

export enum UserSettingsKeys {
    UCM_RESOLVED_SORT = 'ucm_resolved_sort',
    UCM_RESOLVED_COLUMNS = 'ucm_resolved_columns',
    UCM_RESOLVED_GROUP_BY = 'ucm_resolved_groupBy',
    UCM_OPEN_SORT = 'ucm_open_sort',
    UCM_OPEN_COLUMNS = 'ucm_open_columns',
    UCM_OPEN_GROUP_BY = 'ucm_open_groupBy',
    UCV_CLIENTS_COLUMNS = 'ucv_clients_columns',
    PERIODIC_UCV_CLIENTS_COLUMNS = 'periodic_ucv_clients_columns',
    UCV_CLIENTS_SORT = 'ucv_clients_sort',
    CASE_DETAILS_ALERTS_SORT = 'case_details_alerts_sort',
    CASE_DETAILS_ALERTS_COLUMNS = 'case_details_alerts_columns',
    ADVANCED_TABLE_ALERTS_SORT = 'advanced_table_alerts_sort',
    ADVANCED_TABLE_ALERTS_COLUMNS = 'advanced_table_alerts_columns',
    ADVANCED_TABLE_CASES_SORT = 'advanced_table_cases_sort',
    ADVANCED_TABLE_CASES_COLUMNS = 'advanced_table_cases_columns',
    ADVANCED_TABLE_APPLICATIONS_SORT = 'advanced_table_applications_sort',
    ADVANCED_TABLE_APPLICATIONS_COLUMNS = 'advanced_table_applications_columns',
    ADVANCED_TABLE_TRANSACTIONS_SORT = 'advanced_table_transactions_sort',
    ADVANCED_TABLE_TRANSACTIONS_COLUMNS = 'advanced_table_transactions_columns',
    ONBOARDING_RESOLVED_SORT = 'onboarding_resolved_sort',
    ONBOARDING_RESOLVED_COLUMNS = 'onboarding_resolved_columns',
    ONBOARDING_OPEN_SORT = 'onboarding_open_sort',
    ONBOARDING_OPEN_COLUMNS = 'onboarding_open_columns',
    ALGO_THRESHOLD_SORT = 'algo_threshold_sort',
    ACTIVITIES_SORT = 'activities_sort',
    FILE_UPLOAD_SORT = 'file_upload_sort',
    REST_API_SORT = 'rest_api_sort',
    AG_ADVANCED_SEARCH_ORDER_COLUMNS_CONFIGURATION = 'AG_ADVANCED_SEARCH_ORDER_TABLE_COLUMNS_CONFIGURATION',
    AG_ADVANCED_SEARCH_EXECUTION_COLUMNS_CONFIGURATION = 'AG_ADVANCED_SEARCH_EXECUTION_COLUMNS_CONFIGURATION',
    AG_ADVANCED_SEARCH_DEX_TRADE_COLUMNS_CONFIGURATION = 'AG_ADVANCED_SEARCH_DEX_TRADE_COLUMNS_CONFIGURATION',
    AG_MODEL_TESTING_COLUMNS_CONFIGURATION = 'AG_MODEL_TESTING_COLUMNS_CONFIGURATION',
    AG_ADVANCED_SEARCH_TRANSACTIONS_COLUMNS_CONFIGURATION = 'AG_ADVANCED_SEARCH_TRANSACTIONS_COLUMNS_CONFIGURATION',
    AG_ALERT_DETAILS_TRANSACTIONS_COLUMNS_CONFIGURATION = 'AG_ALERT_DETAILS_TRANSACTIONS_COLUMNS_CONFIGURATION',
    AG_ADVANCED_SEARCH_CASES_COLUMNS_CONFIGURATION = 'AG_ADVANCED_SEARCH_CASES_COLUMNS_CONFIGURATION',
    AG_ALGO_SETTINGS_CLIENTS_WATCHLIST_COLUMNS_CONFIGURATION = 'AG_ALGO_SETTINGS_CLIENTS_WATCHLIST_COLUMNS_CONFIGURATION',
    AG_ALGO_SETTINGS_WALLETS_WATCHLIST_COLUMNS_CONFIGURATION = 'AG_ALGO_SETTINGS_WALLETS_WATCHLIST_COLUMNS_CONFIGURATION',
    AG_ADVANCED_SEARCH_ALERTS_COLUMNS_CONFIGURATION = 'AG_ADVANCED_SEARCH_ALERTS_COLUMNS_CONFIGURATION',
    AG_BBO_SPREAD_COLUMNS_CONFIGURATION = 'AG_BBO_SPREAD_COLUMNS_CONFIGURATION',
    AG_UCM_OPEN_CASES_COLUMNS_CONFIGURATION = 'AG_UCM_OPEN_CASES_COLUMNS_CONFIGURATION',
    AG_UCM_RESOLVED_CASES_COLUMNS_CONFIGURATION = 'AG_UCM_RESOLVED_CASES_COLUMNS_CONFIGURATION',
    AG_MODEL_TEST_UCM_CASES_COLUMNS_CONFIGURATION = 'AG_MODEL_TEST_UCM_CASES_COLUMNS_CONFIGURATION',
    // AG_MARKET_INSPECTION_ORDERS_COLUMNS_CONFIGURATION = 'AG_MARKET_INSPECTION_ORDERS_COLUMNS_CONFIGURATION',
    // AG_MARKET_INSPECTION_EXECUTIONS_COLUMNS_CONFIGURATION = 'AG_MARKET_INSPECTION_EXECUTIONS_COLUMNS_CONFIGURATION',
    AG_MARKET_INSPECTION_EVENTS_COLUMNS_CONFIGURATION = 'AG_MARKET_INSPECTION_EVENTS_COLUMNS_CONFIGURATION',
    AG_MARKET_INSPECTION_ALERTS_COLUMNS_CONFIGURATION = 'AG_MARKET_INSPECTION_ALERTS_COLUMNS_CONFIGURATION',
    AG_UPLOAD_UCV_RECORDS_COLUMNS_CONFIGURATION = 'AG_UPLOAD_UCV_RECORDS_COLUMNS_CONFIGURATION',
    AG_SYSTEM_FILE_UPLOAD_CONFIGURATION = 'AG_SYSTEM_FILE_UPLOAD_CONFIGURATION',
    AG_SYSTEM_KAFKA_FILE_UPLOAD_CONFIGURATION = 'AG_SYSTEM_KAFKA_FILE_UPLOAD_CONFIGURATION',
    AG_IGNORE_LIST_COLUMNS_CONFIGURATION = 'AG_IGNORE_LIST_COLUMNS_CONFIGURATION'
}

export interface UserSettings {
    [UserSettingsKeys.UCM_RESOLVED_COLUMNS]: string[];
    [UserSettingsKeys.UCM_RESOLVED_SORT]: MatSortable;
    [UserSettingsKeys.UCM_RESOLVED_GROUP_BY]: string;
    [UserSettingsKeys.UCM_OPEN_COLUMNS]: string[];
    [UserSettingsKeys.UCM_OPEN_SORT]: MatSortable;
    [UserSettingsKeys.UCM_OPEN_GROUP_BY]: string;
    [UserSettingsKeys.UCV_CLIENTS_SORT]: MatSortable;
    [UserSettingsKeys.UCV_CLIENTS_COLUMNS]: string;
    [UserSettingsKeys.PERIODIC_UCV_CLIENTS_COLUMNS]: string;
    [UserSettingsKeys.CASE_DETAILS_ALERTS_SORT]: MatSortable;
    [UserSettingsKeys.AG_ADVANCED_SEARCH_ORDER_COLUMNS_CONFIGURATION]: string;
    [UserSettingsKeys.AG_ADVANCED_SEARCH_DEX_TRADE_COLUMNS_CONFIGURATION]: string;
}

export enum ActivationStatus {
    ACTIVATED = 'ACTIVATED',
    INVITED = 'INVITED',
    DEACTIVATED = 'DEACTIVATED',
    RESCINDED = 'RESCINDED',
    DELETED = 'DELETED'
}

export type Team = {
    id: number;
    name: string;
    description?: string;
    defaultAssignOrigins?: OriginType[];
    members: User[];
    defaultAssignee: User;
    defaultEscalee: User;
    visibilitiesAcl?: {
        ORIGIN?: string[]; // default assigned team by origin for case creation
    };
};

export type AccountActorRole = {
    account: string;
    role: 'PRIMARY' | 'COUNTERPARTY';
    actorId: string;
    clientId: string;
    clientProfile: string;
    isClientIdExist: boolean;
};

export type AccountActorRoleDisplayField = 'clientId' | 'account' | 'actorId';

export type SystemEnumsDictionaryValue = {
    name: string;
    shortName?: string;
};

export type SystemEnumsDictionary = {
    [key: string]: SystemEnumsDictionaryValue;
};

export type SystemEnums = {
    caseStatus: SystemEnumsDictionary;
    caseResolutionFlag: SystemEnumsDictionary;
    statFieldName: SystemEnumsDictionary;
    orderBookEntrySide: SystemEnumsDictionary;
    role: SystemEnumsDictionary;
    appRole: SystemEnumsDictionary;
    advancedSearchRecordType: SystemEnumsDictionary;
    alertTypeOrigin: SystemEnumsDictionary;
    alertTypeAlertGroup: SystemEnumsDictionary;
    alertTypeName: SystemEnumsDictionary;
    eventStatus: SystemEnumsDictionary;
    eventSide: SystemEnumsDictionary;
    pendingActionType: SystemEnumsDictionary;
    priorityName: SystemEnumsDictionary;
    genders: SystemEnumsDictionary;
    orderType: SystemEnumsDictionary;
    InvestorType: SystemEnumsDictionary;
    applicationResolutionFlag: SystemEnumsDictionary;
    applicationStatus: SystemEnumsDictionary;
    clientStatus: SystemEnumsDictionary;
    activityStatus: SystemEnumsDictionary;
    FileUploadRecordType: SystemEnumsDictionary;
    KafkaFileUploadSource: SystemEnumsDictionary;
    strReportStatus: SystemEnumsDictionary;
    TMAccountType: SystemEnumsDictionary;
    TMAccountFunction: SystemEnumsDictionary;
    TMType: SystemEnumsDictionary;
    TMStatus: SystemEnumsDictionary;
    algoParameterValue: SystemEnumsDictionary;
    counterPartyType: SystemEnumsDictionary;
    countries: SystemEnumsDictionary;
    employmentStatus: SystemEnumsDictionary;
    investmentGoal: SystemEnumsDictionary;
    modelTestStatus: SystemEnumsDictionary;
    uploadUcvRecordStatus: SystemEnumsDictionary;
    uploadUcvUploadTypes: SystemEnumsDictionary;
    documentType: SystemEnumsDictionary;
    listOfStates: SystemEnumsDictionary;
    transactionsSide: SystemEnumsDictionary;
    periodicReviewStatus: SystemEnumsDictionary;
    vendorCheck: SystemEnumsDictionary;
    jumioAttachmentType: SystemEnumsDictionary;
    breach: SystemEnumsDictionary;
    ucvFields: SystemEnumsDictionary;
    institutionalSubType: SystemEnumsDictionary;
    retailSubType: SystemEnumsDictionary;
    channelsOfCommunication: SystemEnumsDictionary;
    states: SystemEnumsDictionary;
};

export enum Roles {
    PRIMARY = 'PRIMARY',
    COUNTERPARTY = 'COUNTERPARTY'
}

/**
 * This class handles applying accounts actors and main roles onto the class.
 * Inheriting (using extend) this class is a quick solution to avoid
 * code duplication on Case and Alert.
 * TODO (ayelet): Consider cleaner implementation.
 * Thoughts: I saw something that looked interesting in the base class of MatIcon.
 */
export class AccountActorRoles {
    aar: AccountActorRole[];

    primaryAccounts: string[] = [];
    primaryActors: string[] = [];
    primaryClients: string[] = [];
    counterPartyAccounts: string[] = [];
    counterPartyActors: string[] = [];
    counterPartyClients: string[] = [];
    primaries: AccountActorRole[];
    counterParties: AccountActorRole[];

    accounts: string[] = [];
    actors: string[] = [];
    clients: string[] = [];

    initMainActors(): void {
        const primaries = this.aar.filter(arr => arr.role === Roles.PRIMARY);
        const primaryAccounts = this.removeEmptyOrNullValues(primaries.map(p => p.account));
        const primaryActors = this.removeEmptyOrNullValues(primaries.map(p => p.actorId));
        const primaryClients = this.removeEmptyOrNullValues(primaries.map(p => p.clientId));
        this.primaryAccounts = this.removeDuplicates(primaryAccounts);
        this.primaryActors = this.removeDuplicates(primaryActors);
        this.primaryClients = this.removeDuplicates(primaryClients);
        this.primaries = this.removeDuplicatesByClientId(primaries);

        const counterParties = this.aar.filter(arr => arr.role === Roles.COUNTERPARTY);
        const counterPartyAccounts = this.removeEmptyOrNullValues(counterParties.map(p => p.account));
        const counterPartyActors = this.removeEmptyOrNullValues(counterParties.map(p => p.actorId));
        const counterPartyClients = this.removeEmptyOrNullValues(counterParties.map(p => p.clientId));
        this.counterPartyAccounts = this.removeDuplicates(counterPartyAccounts);
        this.counterPartyActors = this.removeDuplicates(counterPartyActors);
        this.counterPartyClients = this.removeDuplicates(counterPartyClients);
        this.counterParties = this.removeDuplicatesByClientId(counterParties);
    }

    private removeDuplicatesByClientId(array: AccountActorRole[]): AccountActorRole[] {
        const clientIds = array.map(p => p.clientId);
        return array.filter((item, index) => clientIds.indexOf(item.clientId) === index);
    }

    private removeDuplicates(array: string[]): string[] {
        return array.filter((item, index) => array.indexOf(item) === index);
    }

    private removeEmptyOrNullValues(array: string[]): string[] {
        return array.filter(item => !isNullOrUndefined(item) && item.length !== 0);
    }

    initAccountActors(): void {
        const accounts = this.aar.filter(aar => aar.account).map(aar => aar.account);
        this.accounts = this.unique(accounts).sort();

        const actors = this.aar.filter(aar => aar.actorId).map(aar => aar.actorId);
        this.actors = this.unique(actors).sort();

        const clients = this.aar.filter(aar => aar.clientId).map(aar => aar.clientId);
        this.clients = this.unique(clients).sort();
    }

    protected unique(items: string[]): string[] {
        const s = new Set<string>(items);
        return Array.from(s);
    }

    constructor(dtos: AccountActorRole[]) {
        this.aar = dtos || [];
        this.initAccountActors();
        this.initMainActors();
    }
}

export type Dictionary<T> = {
    [key: string]: T;
};

export type ParticipantType = 'clientIds' | 'actorIds' | 'accountIds';

export type ParticipantEvents = {
    id?: string;
    type?: ParticipantType;
    color?: string;
    events: MSEventType[];
};

export enum MarkerIcon {
    news = 'news'
}

export type SimpleMarker = {
    onClickFn?: (obj?: any) => void;
    description: string[];
    imageName: MarkerIcon;
    label: string;
    date: Date;
    headline: string;
    styleClass?: string;
    price?: number;
};

export type ChartData = {
    symbols: CandlesSet;
    participantEvents: ParticipantEvents[];
    simpleMarkers?: SimpleMarker[];
};

// Internal query object
export type MarketDataQuery = {
    exchanges: string[];
    symbols: string[];
    fromDate: Date;
    toDate: Date;
};

export type StatusCard = {
    count: number;
    avgScore?: number;
    highScore?: number;
    lowScore?: number;
};

export type Paginated<T> = {
    items: T[];
    pageSize: number;
    pageNumber: number;
    total: number;
    sort?: Sort;
    filters?: IColumnFilters;
    loadError?: HttpErrorResponse;
};

export enum OriginType {
    TM = 'TM',
    MS = 'MS',
    KYC = 'KYC'
}

export interface TrdsMatIcon {
    value: string;
    isSvg?: boolean;
}

/*
    This class is used to encode special characters for HttpParams. It is used instead of HttpUrlEncodingCodec
    provided by Angular because of an issue https://github.com/angular/angular/issues/11058.
    Implementation is taken from here https://github.com/angular/angular/issues/18261#issuecomment-338354119
 */
export class CustomHttpParameterCodec implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}

export interface IMiEventDto {
    id: number; // id is for ag-grid table
    eventId: string;
    orderId: string;
    price: number;
    quantity: number;
    livesQty: number;
    side: string;
    transactionTime: number;
    orderStatus: string;
    clientId: string;

    version: number;
    cumQty: number;
    leavesQty: number;
    symbol: string;
    orderType: string;
    eventType: string;
    executionVenue: string;
    actorId: string;
    account: string;
    localCurrency: string;
    localNotional: number;

    securityType: string;
    exchangeSymbol: string;
    positionEffect: string;
    putCall: string;
    strikePrice: number;
    fundingRate: number;
    contractMultiplier: number;
    cfiCode: string;
    exerciseStyle: string;
    strikeValue: number;
}

export interface IMiAlert {
    alertId: string;
    from: number;
    to: number;
    originalTo?: number;
    createdAt: number;
    alertType: string;
    origin: string;
    riskScore: number;
    thresholdBreach: string;
    alertDisplayPeriod?: IPeriod;
    exchanges?: string[];
    symbol?: string[];
    searchIds: string[];
    link: string;
    caseId: string;
    isDerivatives: boolean;
    visualData?: VisualData;
    orderIds: string[];
    executionIds: string[];
    transactionIds?: string[];
    isCrossProduct?: boolean;
    secondarySymbols?: string[];
    modelTestId?: string;
}

export enum VisualDataType {
    dit = 'dit'
}

export interface VisualData {
    type: VisualDataType;
    time: number;
    exchange: string;
    symbol: string;
    newsType: string;
    text: string;
}

export interface IMiAlertDescriptionView extends IMiAlert {
    description: string;
    aarData: AccountActorRoles;
}

export interface ISearchPropertyResponse {
    name: string;
    id?: string;
}
